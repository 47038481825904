<style>
  h1 {
    word-break: break-all;
  }

  h5 {
    margin-bottom: 20px;
  }

  div.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding-bottom: 100px;
  }

  div.box {
    border: 1px solid #e5e8ea;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 56px 40px;
    text-align: left;
  }

  div.box * p {
    margin-bottom: 10px;
  }

  @media (max-width: 840px) {
    h1 {
      font-size: 40px;
      margin-bottom: 0;
    }

    div.box {
      padding: 20px 15px;
    }

    div.root {
      padding-bottom: 30px;
    }
  }
</style>

<script>
  export let currentProvince;
  export let nextProvince;
  export let distanceLeft;
</script>

<div class="root">
  <h5>Progrès</h5>

  <div class="box">
    <div class="container">
      <div class="columns">
        <div class="column col-12">
          <p>Emplacement actuel</p>
          <h1>{currentProvince}</h1>
          {#if distanceLeft > 0}
            <div class="columns">
              <div class="column col-7 col-lg-12">
                <p>Prochain arrêt</p>
                <h2>{nextProvince}</h2>
              </div>
                <div class="column col-5 col-lg-12">

                  <p>Distance à parcourir</p>
                  <h2>{distanceLeft} km</h2>
                </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
