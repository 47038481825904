<style>
  div.root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  @media (min-width: 600px) {
    div.root {
      max-height: 100vh;
    }
  }

  header {
    padding: 80px 80px 0 80px;
    transition: all 0.16s ease-in-out;
    flex-shrink: 0;
  }

  @media (max-width: 840px) {
    header {
      padding: 40px 40px 0 40px;
    }
  }

  @media (max-width: 600px) {
    header {
      padding: 20px 20px 0 20px;
    }
  }

  h2 {
    margin-bottom: 40px;
  }
</style>

<script>
  import Router from "svelte-static-hash-router/Router.svelte";
  import Home from "./home/Home.svelte";
  import Rankings from "./rankings/Rankings.svelte";
</script>

<div class="root">
  <header>
    <h2>Nexapp Cross Canada 🇨🇦</h2>
  </header>

  <Router
    routes="{[{ path: '/', component: Home }, { path: '/rankings', component: Rankings }]}"
  />
</div>
