<script>
  import confetti from "canvas-confetti";

  import {distanceLeft} from '../../stores/store';

  var duration = 7 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 20, spread: 300, ticks: 60, zIndex: 0 };
  var defaults2 = { startVelocity: 50, spread: 750, ticks: 60, zIndex: 0 };
  var colors = ['#002a44', '#03b7e2', '#91ed00'];
  var colorsSecondary = ['#80dbf1', '#d3f899', '#b5f9f3'];
  var colorsTertiary = ['#4af1e3', '#02ece8'];
  const allColors = [...colors, colorsSecondary, colorsTertiary];

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  distanceLeft.subscribe((value) => {
    if (value !== null && value <= 0) {

      var interval = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        var particleCount = 40 * (timeLeft / duration);
        var particleCountSmall = 10 * (timeLeft / duration);
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }, colors: allColors }));
        confetti(Object.assign({}, defaults, { particleCount: particleCountSmall, origin: { x: randomInRange(0.3, 0.7), y: Math.random() - 0.2 }, colors: allColors }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }, colors: allColors }));
      }, 350);


      function cornerCannons(count2) {
        var count = count2;
        var defaults3 = {
          origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
          confetti(Object.assign({}, defaults3, opts, {
            particleCount: Math.floor(count * particleRatio),
            colors
          }));
        }

        fire(0.25, {
          spread: 26,
          startVelocity: 55,
          origin: { x: 0, y: 1 },
          angle: randomInRange(25, 125)
        });
        fire(0.2, {
          spread: 60,
          origin: { x: 0, y: 1 },
          angle: randomInRange(25, 125)
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          origin: { x: 0, y: 1 },
          angle: randomInRange(25, 125)
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          origin: { x: 0, y: 1 },
          angle: randomInRange(25, 125)
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
          origin: { x: 0, y: 1 },
          angle: randomInRange(25, 125)
        });



        fire(0.25, {
          spread: 26,
          startVelocity: 55,
          origin: { x: 1, y: 1 },
          angle: randomInRange(110,170)
        });
        fire(0.2, {
          spread: 60,
          origin: { x: 1, y: 1 },
          angle: randomInRange(110,170)
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          origin: { x: 1, y: 1 },
          angle: randomInRange(110,170)
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          origin: { x: 1, y: 1 },
          angle: randomInRange(110,170)
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
          origin: { x: 1, y: 1 },
          angle: randomInRange(110,170)
        });
      }

      setTimeout(() => cornerCannons(300), 100);

      var interval2 = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval2);
        }

        var count = 125 * (timeLeft / duration);

        cornerCannons(count);
        
      }, 1550);
    }
  });
</script>