<style>

</style>

<script>
  import ProvinceProgress from "../components/ProvinceProgress.svelte";

  import { rankings } from "../../stores/store";

  const bruh = {
    Québec: 0,
    Ontario: 320,
    Manitoba: 2420,
    Saskatchewan: 2920,
    Alberta: 3580,
    "Colombie-Britannique": 4130,
    Vancouver: 4930,
  };

  function getCurrentProvince(rankings) {
    const totalDistance = rankings.reduce((acc, next) => {
      return acc + next.totalDistance;
    }, 0);

    const wewe = Object.entries(bruh).filter((a) => totalDistance >= a[1]);
    return wewe[wewe.length - 1][0];
  }

  function getNextProvince(rankings) {
    const totalDistance = rankings.reduce((acc, next) => {
      return acc + next.totalDistance;
    }, 0);
    return Object.entries(bruh).filter((a) => totalDistance <= a[1])[0] || ['done', 0];
  }

  function getTotalDistance(rankings) {
    const totalDistance = rankings.reduce((acc, next) => {
      return acc + next.totalDistance;
    }, 0);
    return totalDistance;
  }

  let data;

  rankings.subscribe((value) => {
    data = value;
  });

  $: currentProvince = getCurrentProvince(data);
  $: nextProvince = getNextProvince(data)[0];
  $: distanceLeft =
    Math.round((getNextProvince(data)[1] - getTotalDistance(data)) * 100) / 100;
</script>

<ProvinceProgress
  currentProvince="{currentProvince}"
  nextProvince="{nextProvince}"
  distanceLeft="{distanceLeft}"
/>
