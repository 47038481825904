<style>
  div {
    height: 100%;
  }
</style>

<script>
  import { onMount } from "svelte";
  import { rankings, isNetworkActive, distanceLeft, currentProvince, nextProvince } from "./stores/store";

  import App from "./App.svelte";

const bruh = {
  Québec: 0,
  Ontario: 320,
  Manitoba: 2420,
  Saskatchewan: 2920,
  Alberta: 3580,
  "Colombie-Britannique": 4130,
  Vancouver: 4930,
};

function getCurrentProvince(rankings) {
  const totalDistance = rankings.reduce((acc, next) => {
    return acc + next.totalDistance;
  }, 0);

  const wewe = Object.entries(bruh).filter((a) => totalDistance >= a[1]);
  return wewe[wewe.length - 1][0];
}

function getNextProvince(rankings) {
  const totalDistance = rankings.reduce((acc, next) => {
    return acc + next.totalDistance;
  }, 0);
  return Object.entries(bruh).filter((a) => totalDistance <= a[1])[0] || ['done', 0];
}

function getTotalDistance(rankings) {
  const totalDistance = rankings.reduce((acc, next) => {
    return acc + next.totalDistance;
  }, 0);
  return totalDistance;
}

  onMount(async () => {
    isNetworkActive.update(() => true);
    const res = await fetch(
      `https://spreadsheets.google.com/feeds/list/${process.env.GOOGLE_SHEETS_ID}/${process.env.GOOGLE_SHEETS_INDEX}/public/values?alt=json`
    );
    const temp = await res.json();
    const result = temp.feed.entry
      .map((a) => ({
        name: a.gsx$name.$t,
        totalDistance: parseFloat(a.gsx$totaldistance.$t),
      }))
      .sort((a, b) => (a.totalDistance > b.totalDistance ? -1 : 1));

    rankings.update(() => result);
    nextProvince.update(() => {
      const province = getNextProvince(result);

      if (!!province) {
        return province[0];
      }
    });
    currentProvince.update(() =>  getCurrentProvince(result));

    setTimeout(() => {
      isNetworkActive.update(() => false)
      
      distanceLeft.update(() => Math.round((getNextProvince(result)[1] - getTotalDistance(result)) * 100) / 100);
    }, 500);
  });

  let isLoading = false;

  isNetworkActive.subscribe((value) => {
    isLoading = value;
  });
</script>

<div>
  {#if isLoading}
    <div class="loading loading-lg"></div>
  {:else}
    <App />
  {/if}
</div>
