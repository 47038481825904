<style>
  .rank {
    flex: 1;
    border: 1px solid #e5e8ea;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.16s ease-in-out;
  }

  @media (max-width: 840px) {
    .rank {
      padding: 18px 24px;
    }
  }

  .rank:not(:last-child) {
    margin-bottom: 16px;
  }

  .rank h3,
  .rank h4 {
    margin-bottom: 0;
  }

  .rank .left {
    display: flex;
    align-items: center;
  }

  .rank .left img {
    width: 24px;
    height: 22px;
    margin-right: 20px;
  }

  .position {
    color: #99a6ae;
    width: 24px;
    margin-right: 20px;
  }
</style>

<script>
  import gold from "../../static/img/star-gold.png";
  import silver from "../../static/img/star-silver.png";
  import bronze from "../../static/img/star-bronze.png";
  export let position;
  export let ranking;
</script>

<div class="rank">
  <span class="left">
    {#if position === 1}
      <img src="{gold}" />
    {:else if position === 2}
      <img src="{silver}" />
    {:else if position === 3}
      <img src="{bronze}" />
    {:else}
      <h4 class="position">{position}</h4>
    {/if}
    <h3>{ranking.name}</h3>
  </span>

  <span>
    <h4>{ranking.totalDistance} KM</h4>
  </span>
</div>
