<style>
  footer {
    min-height: 160px;
    background-color: #002034;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 960px) {
    .timeline .progress-point:not(.next):not(:first-child):not(:last-child) {
      opacity: 0;
    }
  }

  .timeline {
    width: 100%;
    background-color: #99a6ae;
    height: 2px;
    position: relative;
  }

  .timeline:after {
    width: var(--distanceWidth);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: #91ed00;
    height: 2px;
  }

  .progress-point {
    position: absolute;
    top: -11px;
    transition: all 0.32s ease-in-out;
  }

  .progress-point.ontario {
    left: 6.49%;
  }

  .progress-point.manitoba {
    left: 49.08%;
  }

  .progress-point.sask {
    left: 59.22%;
  }

  .progress-point.alberta {
    left: 72.61%;
  }

  .progress-point.bc {
    left: 83.77%;
  }
  .progress-point.vancouver {
    right: 0;
  }

  .progress-text {
    color: #91ed00;
    transform: translateY(-30px) translateX(-50%);
    position: absolute;
    white-space: nowrap;
  }

  .progress-text.far {
    color: #99a6ae;
  }

  .progress-point:before {
    border: 2px solid transparent;
    background: #99a6ae;
    border-radius: 50%;
    content: "";
    display: block;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 0.3rem;
    transform: translateX(-50%);
    width: 12px;
    z-index: 1;
  }

  .progress-point.next:before {
    background: #002034;
    border: 2px solid #91ed00;
    box-sizing: content-box;
    top: 0.2rem;
  }

  .progress-point.visited:before {
    background: #91ed00;
    border: 2px solid #002034;
    border-radius: 50%;
    content: "";
    display: block;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 0.2rem;
    transform: translateX(-50%);
    width: 12px;
    z-index: 1;
    box-sizing: content-box;
  }
</style>

<script>
  import cssVars from "svelte-css-vars";

  export let progress;
  export let distance;
  export let nextDestination;

  console.log('progress', progress)

  $: styleVars = {
    distanceWidth: Math.min(progress, 1) * 100 + "%",
  };3

  const bruh = {
    Québec: 0,
    Ontario: 320,
    Manitoba: 2420,
    Saskatchewan: 2920,
    Alberta: 3580,
    "Colombie-Britannique": 4130,
    Vancouver: 4930,
  };

  function hasVisited(city) {
    return distance >= bruh[city];
  }

  function isNext(city) {
    return city === nextDestination;
  }
</script>

<footer>
  <div class="timeline" use:cssVars="{styleVars}">
    <div
      class="progress-point quebec"
      class:visited="{hasVisited('Québec')}"
      class:next="{isNext('Québec')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Québec') || isNext('Québec')}"
        class:far="{!(hasVisited('Québec') || isNext('Québec'))}"
      >
        Nexapp
      </p>
    </div>

    <div
      class="progress-point ontario"
      class:visited="{hasVisited('Ontario')}"
      class:next="{isNext('Ontario')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Ontario') || isNext('Ontario')}"
        class:far="{!(hasVisited('Ontario') || isNext('Ontario'))}"
      >
        Ontario
      </p>
    </div>

    <div
      class="progress-point manitoba"
      class:visited="{hasVisited('Manitoba')}"
      class:next="{isNext('Manitoba')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Manitoba') || isNext('Manitoba')}"
        class:far="{!(hasVisited('Manitoba') || isNext('Manitoba'))}"
      >
        Manitoba
      </p>
    </div>

    <div
      class="progress-point sask"
      class:visited="{hasVisited('Saskatchewan')}"
      class:next="{isNext('Saskatchewan')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Saskatchewan') || isNext('Saskatchewan')}"
        class:far="{!(hasVisited('Saskatchewan') || isNext('Saskatchewan'))}"
      >
        Saskatchewan
      </p>
    </div>

    <div
      class="progress-point alberta"
      class:visited="{hasVisited('Alberta')}"
      class:next="{isNext('Alberta')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Alberta') || isNext('Alberta')}"
        class:far="{!(hasVisited('Alberta') || isNext('Alberta'))}"
      >
        Alberta
      </p>
    </div>

    <div
      class="progress-point bc"
      class:visited="{hasVisited('Colombie-Britannique')}"
      class:next="{isNext('Colombie-Britannique')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Colombie-Britannique') || isNext('Colombie-Britannique')}"
        class:far="{!(hasVisited('Colombie-Britannique') || isNext('Colombie-Britannique'))}"
      >
        Colombie-Britannique
      </p>
    </div>

    <div
      class="progress-point vancouver"
      class:visited="{hasVisited('Vancouver')}"
      class:next="{isNext('Vancouver')}"
    >
      <p
        class="progress-text"
        class:bold="{hasVisited('Vancouver') || isNext('Vancouver')}"
        class:far="{!(hasVisited('Vancouver') || isNext('Vancouver'))}"
      >
        Vancouver
      </p>
    </div>
  </div>
</footer>
