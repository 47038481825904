<style>
  table {
    max-width: 800px;
  }

  @media (max-width: 840px) {
    .root {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .rankings {
    max-width: 524px;
    flex: 1;
    margin-left: 152px;
    display: flex;
    flex-direction: column;
    margin-bottom: 140px;
  }

  @media (max-width: 840px) {
    .rankings {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  h5 {
    align-self: flex-start;
    margin-bottom: 24px;
  }
</style>

<script>
  import Link from "svelte-static-hash-router/Link.svelte";

  import MVPTile from "../../home//components/MVPTile.svelte";

  export let data;
</script>

<div class="columns root">
  <Link to="/">← Retour</Link>

  {#if !!data}
    <div class="rankings">
      <h5>Classement</h5>

      {#each data as item, i}
        <MVPTile
          position="{i + 1}"
          ranking="{{ ...item, totalDistance: item.totalDistance.toFixed(2) }}"
        />
      {/each}
    </div>
  {/if}
</div>
