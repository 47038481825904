<style>

</style>

<script>
  import RankingTable from "../components/RankingTable.svelte";

  import { rankings } from "../../stores/store";

  let data;

  const unsubscribe = rankings.subscribe((value) => {
    data = value;
  });
</script>

<RankingTable data="{data}" />
