<style>

</style>

<script>
  import Footer from "../components/Footer.svelte";

  import { rankings } from "../../stores/store";

  let data;

  rankings.subscribe((value) => {
    data = value;
  });

  const bruh = {
    Québec: 0,
    Ontario: 320,
    Manitoba: 2420,
    Saskatchewan: 2920,
    Alberta: 3580,
    "Colombie-Britannique": 4130,
    Vancouver: 4930,
  };

  function getTotalDistance(rankings) {
    const totalDistance = rankings.reduce((acc, next) => {
      return acc + next.totalDistance;
    }, 0);
    return totalDistance;
  }

  function getNextProvince(rankings) {
    const totalDistance = rankings.reduce((acc, next) => {
      return acc + next.totalDistance;
    }, 0);
    return Object.entries(bruh).filter((a) => totalDistance <= a[1])[0] || ['done', 0];
  }

  $: progress = getTotalDistance(data) / 4930;
  $: nextDestination = getNextProvince(data)[0];
  $: distance = getTotalDistance(data);
</script>

<Footer
  progress="{progress}"
  nextDestination="{nextDestination}"
  distance="{distance}"
/>
