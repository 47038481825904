<style>
  h5 {
    margin-bottom: 20px;
  }

  div.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  div.box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  div.box > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .link {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    min-height: 100px;
    width: 100%;
    align-items: center;
    padding-bottom: 40px;
  }
</style>

<script>
  import Link from "svelte-static-hash-router/Link.svelte";

  import MVPTile from "./MVPTile.svelte";

  export let first;
  export let second;
  export let third;
</script>

<div class="root">
  <h5>MVP</h5>

  <div class="box">
    {#if !!first}
      <MVPTile ranking="{first}" position="{1}" />
    {/if}

    {#if !!second}
      <MVPTile ranking="{second}" position="{2}" />
    {/if}

    {#if !!third}
      <MVPTile ranking="{third}" position="{3}" />
    {/if}
  </div>

  <span class="link">
    <Link to="/rankings">Voir le classement →</Link>
  </span>
</div>
