<style>

</style>

<script>
  import MVP from "../components/MVP.svelte";

  import { rankings } from "../../stores/store";

  let data;

  rankings.subscribe((value) => {
    data = value;
  });
</script>

<MVP first="{data[0]}" second="{data[1]}" third="{data[2]}" />
